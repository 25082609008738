html {
	font-family: 'Open Sans', monospace;
	min-height: 100vh;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

a {
	color: inherit;
}

body {
	position: relative;
	min-height: 100vh;
	background-color: rgb(100, 100, 100);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
}

#root {
	line-height: 1.3;
}

header {
	background-color: rgb(30, 30, 30, 0.7);
	padding: 0.5em;
	color: rgb(240, 240, 240);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1em;
}

h1 {
	font-size: 1.5em;
	text-align: center;
}

h2 {
	font-size: 2em;
}

.units button {
	font-size: 1.5em;
}

.units button + button {
	margin-left: 0.3em;
}
.selected {
	background-color: mediumseagreen;
}

.weatherdetails,
.locations-item {
	padding: 1em 0.5em;
	background-color: rgba(230, 230, 230, 0.75);
	margin: 1em;
	text-align: center;
	border-radius: 10px;
}

.weatherdetails-date {
	font-size: 1.2em;
	margin-bottom: 0.5em;
}

.weatherdetails-time {
	font-size: 2em;
}

.weatherdetails-temp {
	font-size: 4em;
	margin-bottom: 0.2em;
}

.forecast {
	display: flex;
	justify-content: space-around;
	gap: 1em;
}

.forecast img {
	width: 75px;
}

.forecast-day {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer {
	margin-top: 1em;
}

.locations {
	display: flex;
	flex-direction: column;
	margin: 1em;
	justify-content: center;
}

.location {
	display: flex;
	gap: 0.5em;
}

.locations-item {
	padding: 0.5em 1em;
	margin: 0.5em 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 3;
	gap: 2em;
}

.locations-item:hover {
	background-color: mediumseagreen;
	cursor: pointer;
}

.locations-item-delete {
	font-size: 0.8em;
	align-self: center;
	padding: 0.3em;
}

.locations-item-delete:hover {
	background-color: mediumseagreen;
}

.locations-item:focus {
	background-color: mediumseagreen;
}

.locations-item-citytime {
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.3em;
}

.locations-item-time {
	font-size: 1.6em;
}

.locations-item-temp {
	font-size: 2em;
	display: flex;
	gap: 0.5em;
}

.addlocation {
	display: flex;
	margin: 0.5em 1em;
}

.addlocation input {
	margin: 0;
	padding: 0 0.5em;
	font-size: 1.2em;
	min-width: 0;
	flex-grow: 3;
}

.addlocation-plus {
	font-size: 2.5em;
}

.error {
	position: relative;
	background-color: white;
	color: red;
	font-size: 1.2em;
	padding: 0.5em;
	width: 80%;
	max-width: 600px;
	margin: 1em auto;
	border-radius: 5px;
}

.error-close {
	position: absolute;
	top: 2px;
	right: 2px;
}

/* https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements */

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@media screen and (min-width: 800px) {
	header {
		gap: 3em;
	}
	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 3em;
	}
	.weatherdetails {
		width: 80%;
		max-width: 800px;
		margin: 2em auto;
	}
	.weatherdetails-heading {
		display: flex;
		justify-content: space-around;
		margin-bottom: 3em;
	}
	.weatherdetails-temp {
		align-self: flex-end;
	}
	.locations {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 2em;
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;
	}
	.locations-item-citytime {
		font-size: 1.2em;
	}
	.locations-item-temp {
		font-size: 2em;
	}
	.locations-item-time {
		font-size: 1.5em;
	}
	.locations-item-delete {
		font-size: 1.5em;
	}
	.forecast-day {
		font-size: 2em;
	}
	.forecast img {
		width: 120px;
	}
	.addlocation {
		max-width: 400px;
		margin: 2em auto;
	}
}
